import { useEffect, useState } from "react";
import "./Cupos.css";
import { useAppDispatch } from "../../app/store";
import {
  selectCuadraturaCupos,
  selectDetalleCupo,
} from "../../features/Dashboard/Slices/dashboardSlice";
import Input from "../../components/reusable/Input/Input";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import TableList from "../../components/TableList/TableList";
import TableListInverted from "../../components/TableListInverted/TableListInverted";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastInfo, toastSuccess } from "../../utils/toast";
import { getDetalleDeCupo } from "../../features/Dashboard/AsyncThunks/cupos/getDetalleDeCupo";
import {
  getDetalleCuadratura,
  procesarCuadratura,
} from "../../features/Dashboard/AsyncThunks/cupos/getDetalleCuadratura";

function Cupos() {
  const [loading, setLoading] = useState(false);
  const [idCupo, setIdCupo] = useState<number | null>(null);
  const detalleCupo = useSelector(selectDetalleCupo);
  const cuadraturaCupo = useSelector(selectCuadraturaCupos);
  const dispatch = useAppDispatch();

  const updateCupo = async () => {
    if (idCupo) {
      setLoading(true);
      await dashboardAPI.actualizarCupo(idCupo);
      //await dispatch(getDetalleDeCupo(idCupo));
      toastSuccess("En unos instantes se actualizará la información");
      setLoading(false);
    } else {
      toastInfo("Ingrese idCupo");
    }
  };

  const syncCuposDescuadrados = async () => {
    setLoading(true);
    await dashboardAPI.syncCuposDescuadrados();
    toastSuccess("En unos instantes se actualizará la información");
    setLoading(false);
  };

  const getCuadratura = async () => {
    setLoading(true);
    await dispatch(getDetalleCuadratura());
    setLoading(false);
  };

  const calcularCuadratura = async () => {
    setLoading(true);
    await dispatch(procesarCuadratura());
    setLoading(false);
  };

  const getDetalleCupo = async () => {
    if (idCupo) {
      setLoading(true);
      await dispatch(getDetalleDeCupo(idCupo));
      setLoading(false);
    } else {
      toastInfo("Ingrese idCupo");
    }
  };
  useEffect(() => {
    console.log("detalleCupo :>> ", detalleCupo);
  }, [detalleCupo]);

  return (
    <div className="p-5 flex-1 bg-white h-full">
      {loading && <PageLoader />}
      <div className="flex flex-row">
        <Input
          onChange={(text) => setIdCupo(Number(text))}
          type="number"
          placeholder="Ingrese idCupo (número)"
          className="w-3/12"
        />
        <Button className="ml-3" onClick={getDetalleCupo}>
          <p>Buscar</p>
        </Button>
        <Button className="ml-3" theme="blue" onClick={updateCupo}>
          <p>Actualizar desde BO</p>
        </Button>
        <Button className="ml-3" onClick={getCuadratura}>
          <p>Consultar Cuadratura</p>
        </Button>
        <Button className="ml-3"  theme="green" onClick={calcularCuadratura}>
          <p>Calcular Cuadratura</p>
        </Button>
        <Button className="ml-3" theme="blue" onClick={syncCuposDescuadrados}>
          <p>Sincronizar Cupos Descuadrados</p>
        </Button>
      </div>
      <div className="pt-5 w-auto">
        <TableListInverted list={[detalleCupo]} />
      </div>
      <div className="cuadraturaContainer px-5">
        <strong>Cuadratura de cupos</strong>
        {cuadraturaCupo.length > 0 ? (
          <TableList list={cuadraturaCupo} />
        ) : (
          <p>No se encuentran datos de cuatratura</p>
        )}
      </div>
    </div>
  );
}

export default Cupos;
