import React from 'react';

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
}

export const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  label,
  className = '',
  placeholder = 'YYYYMMDD',
  required = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\D/g, '').slice(0, 8);
    
    // Validar formato YYYYMMDD
    if (newValue.length === 8) {
      const year = parseInt(newValue.substring(0, 4));
      const month = parseInt(newValue.substring(4, 6));
      const day = parseInt(newValue.substring(6, 8));
      
      const date = new Date(year, month - 1, day);
      const isValid = date.getFullYear() === year && 
                     (date.getMonth() + 1) === month && 
                     date.getDate() === day;
                     
      if (isValid) {
        onChange(newValue);
      }
    } else {
      onChange(newValue);
    }
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label className="mb-1 text-sm font-medium">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
        maxLength={8}
        required={required}
      />
    </div>
  );
};