import { useEffect, useState } from "react";
import Button from "../../../components/reusable/Button/Button";
import TableList from "../../../components/TableList/TableList";
import PageLoader from "../../../components/reusable/PageLoader/PageLoader";
import Input from "../../../components/reusable/Input/Input";
import { useAppDispatch } from "../../../app/store";
import { useSelector } from "react-redux";
import { inicializarAlumno } from "../../../features/Dashboard/AsyncThunks/alumnos/inicializarAlumno";
import {
  getAlumnosInicializados,
  postBorrarIPO,
  postBorrarTodosLosAlumnos,
  postInvalidarOfertas,
  postResetearIPO,
  postSincronizarAlumnos,
  postVencerOfertas,
} from "../../../features/Dashboard/AsyncThunks/alumnos/getAlumnosInicializados";
import { selectAlumnosInicializados } from "../../../features/Dashboard/Slices/alumnosSlice";
import { setLoadingPageScreen } from "../../../features/Dashboard/Slices/dashboardSlice";
import ModalConfirmation from "../../../components/reusable/ModalConfirmation/ModalConfirmation";

function AlumnosInicializados() {
  const alumnosInicializados = useSelector(selectAlumnosInicializados);
  const [email, setEmail] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [programa, setPrograma] = useState("");
  const [orientacion, setOrientacion] = useState("");
  const dispatch = useAppDispatch();
  const confirmationModalInitialState = {
    show: false,
    title: "",
    dialog: "",
    onConfirm: () => {},
  };
  const [confirmationModal, setConfirmationModal] = useState(
    confirmationModalInitialState
  );
  const clearConfirmationModal = () => {
    setConfirmationModal(confirmationModalInitialState);
  };

  const setLoading = (loaderState: boolean) => {
    dispatch(setLoadingPageScreen(loaderState));
  };

  useEffect(() => {
    ObtenerAlumnosInicializados();
  }, []);

  const handleInicializarAlumno = async () => {
    setLoading(true);
    clearConfirmationModal();
    await dispatch(inicializarAlumno(email));
    setEmail("");
    setLoading(false);
  };

  const ObtenerAlumnosInicializados = async () => {
    setLoading(true);
    try {
      await dispatch(getAlumnosInicializados());
    } catch (error) {
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const BorrarTodosLosAlumnos = async () => {
    setLoading(true);
    try {
      await dispatch(postBorrarTodosLosAlumnos());
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const VencerOfertas = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postVencerOfertas({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const BorrarIPO = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postBorrarIPO({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const ResetearIPO = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postResetearIPO({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const InvalidarOfertas = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postInvalidarOfertas({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const SincronizarAlumnos = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postSincronizarAlumnos({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  return (
    <div className="flex w-full justify-around px-5 py-5">
      <ModalConfirmation
        show={confirmationModal.show}
        onClose={clearConfirmationModal}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.dialog}
      />
      <div className="flex flex-grow flex-row">
        <div className="flex flex-grow flex-col mt-5">
          <b className="text-center">Inicializar alumno</b>
          <div className="flex flex-row justify-center mt-2">
            <Input
              className="w-full"
              placeholder="Ingrese uno o varios mails de alumno separados por , para inicializarlos"
              onChange={(text) => setEmail(text)}
            />
            <Button
              className="ml-3 w-4/12"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: handleInicializarAlumno,
                  title: "¿Desea inicializar los siguientes mails?",
                  dialog: email,
                });
              }}
              label="Inicializar"
            />
          </div>
          <div className="flex flex-row items-center">
            <Input
              className="ipoField"
              placeholder="Identificación"
              onChange={(text) => setIdentificacion(text)}
            />
            <Input
              className="ipoField"
              placeholder="Programa"
              onChange={(text) => setPrograma(text)}
            />
            <Input
              className="ipoField"
              placeholder="Orientación"
              onChange={(text) => setOrientacion(text)}
            />
            <Button
              className="ml-3 w-3/12"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: SincronizarAlumnos,
                  title: `¿Desea inicializar alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                  dialog: "",
                });
              }}
              label="Inicializar"
              theme="blue"
            />
            <Button
              className="ml-3 w-3/12"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: ResetearIPO,
                  title: `¿Desea resetear alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                  dialog: "",
                });
              }}
              label="Resetear"
              theme="blue"
            />
            <Button
              className="ml-3 w-3/12"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: BorrarIPO,
                  title: `¿Desea eliminar alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                  dialog: "",
                });
              }}
              label="Borrar"
              theme="red"
            />
            <Button
              className="ml-4 w-4/12"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: InvalidarOfertas,
                  title: `¿Desea invalidar ofertas para el IPO ${identificacion}-${programa}-${orientacion} ?`,
                  dialog: "",
                });
              }}
              label="Invalidar Ofertas"
              theme="yellow"
            />
            <Button
              className="ml-4 w-4/12"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: VencerOfertas,
                  title: `¿Desea vencer ofertas para el IPO ${identificacion}-${programa}-${orientacion} ?`,
                  dialog: "",
                });
              }}
              label="Vencer Ofertas"
              theme="red"
            />
          </div>
        </div>

        <div className="flex flex-grow flex-col items-center">
          <div className="h-auto mb-5 w-auto justify-center mt-2">
            <TableList list={alumnosInicializados} enableSearchbar={false} />
          </div>

          <div className="flex flex-row items-center">
            <Button
              className="self-center h-6"
              onClick={ObtenerAlumnosInicializados}
              label="Actualizar"
            />

            <Button
              className="self-center h-6"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: BorrarTodosLosAlumnos,
                  title: `¿Desea borrar todos los alumnos?`,
                  dialog: "",
                });
              }}
              label="Borrar Todos"
              theme="red"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlumnosInicializados;
