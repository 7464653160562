import { useState, KeyboardEvent } from "react";
import "./Login.css";
import logo from "../../assets/ditella-logo.png";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import { useNavigate } from "react-router-dom";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import ditellaBg from "../../assets/ditella-bg.jpg";

import { CredentialResponse } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import {
  setAuthtenticated,
  setToken,
  setUser,
} from "../../features/Dashboard/Slices/userSlice";
import { useAppDispatch } from "../../app/store";
import { dashLogin } from "../../Api/authentication";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
const pjson = require("../../../package.json");


const Login = () => {
  const isDevEnv =
    process.env.REACT_APP_ENV === "Dev" ||
    process.env.REACT_APP_ENV === "Testing" ||
    process.env.REACT_APP_ENV === "Localhost";
  const version = `-v ${pjson.version}`;

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [logingIn, setLogingIn] = useState(false);
  const dispatch = useAppDispatch();

  const loginUser = (response: any) => {
    if (response.token) {
      dispatch(setAuthtenticated(true));
      dispatch(setToken(response.token));
      dispatch(setUser(response.info));
      localStorage.setItem("token", response.token);
      navigate("/");
    } else {
      console.log("Error");
      setLogingIn(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSubmit = async () => {
    if (email.length > 5) {
      setLogingIn(true);
      const response = await dashLogin(email);
      loginUser(response);
    }
  };

  const handleInput = (e: any) => {
    if (e?.target?.value) {
      setEmail(e?.target?.value);
    }
  };

  const handleGoogleLogin = async (res: CredentialResponse) => {
    setLogingIn(true);
    const decoded: any = jwtDecode(res.credential || "");
    if (decoded?.email) {
      const response = await dashLogin(decoded?.email);
      loginUser(response);
    }
  };

  return (
    <div className="login-page">
      <div className="form-login-btn">
        <img src={logo} alt="logo Ditella" width={150} />
        <h1>UTDT Backoffice</h1>
        <p>Identifíquese usando su cuenta autorizada</p>
        {isDevEnv && (
          <form onKeyDown={handleKeyDown}>
            <div className="row">
              <SlInput
                name="email"
                value={email}
                onSlInput={handleInput}
                required
              />
              <SlButton
                size="medium"
                type="button"
                onClick={handleSubmit}
                disabled={logingIn}
                loading={logingIn}
              >
                <SlIcon slot="prefix" name="box-arrow-in-right"></SlIcon>
              </SlButton>
            </div>
          </form>
        )}
        {!logingIn ? (
          <GoogleLogin
            locale="es"
            onSuccess={(credentialResponse: CredentialResponse) => {
              handleGoogleLogin(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
              alert("Login Failed");
            }}
          />
        ) : (
          <SlSpinner />
        )}
        <p>{version}</p>
      </div>
      <img src={ditellaBg} alt="" />
    </div>
  );
};

export default Login;
