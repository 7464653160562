import { useEffect, useState } from "react";
import TableList from "../../components/TableList/TableList";
import Pagination from "../../components/reusable/Pagination/Pagination";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError } from "../../utils/toast";
import "./Transacciones.css";
import Button from "../../components/reusable/Button/Button";
import { useAppDispatch } from "../../app/store";
import { setLoadingPageScreen } from "../../features/Dashboard/Slices/dashboardSlice";
import xlsx from "json-as-xlsx";
import { DateInput } from "../../components/DateInput";
import { TimeInput } from "../../components/TimerInput";

function Transacciones() {
  const [fecha, setFecha] = useState(
    new Date().toISOString().slice(0, 10).replace(/-/g, "")
  );
  const [hora, setHora] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEventos] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventos, setEventos] = useState<any[]>([]);
  const pageSize = 100;
  const dispatch = useAppDispatch();

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  const exportTransacciones = async () => {
    try {
      const fechaNum = parseInt(fecha);
      const horaNum = hora ? parseInt(hora) : null;
      const data = await dashboardAPI.exportTransacciones(fechaNum, horaNum);
      let settings = {
        fileName: "Transacciones", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };

      xlsx(data, settings);
    } catch (error) {
      console.log("ERROR: " + JSON.stringify(error));
      toastError();
    }
  };

  const getTransacciones = async (newPage?: number) => {
    setLoading(true);
    try {
      const page = newPage ? (newPage - 1) * pageSize : 0;

      const fechaHoy = new Date().toISOString().slice(0, 10).replace(/-/g, "");
      const fechaNum = fecha ? parseInt(fecha) : parseInt(fechaHoy);

      const horaNum = hora ? parseInt(hora) : null;
      const newData = await dashboardAPI.getTransaccionesPaginated(
        pageSize,
        page,
        fechaNum,
        horaNum
      );
      if (newPage) {
        setCurrentPage(newPage);
      } else {
        setCurrentPage(1);
      }
      setEventos(newData.transacciones);
      setTotalEventos(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  useEffect(() => {
    getTransacciones();
  }, []);

  return (
    <div className="flex flex-1">
      <div className="transaccionesPage">
        <div className="pt-3 flex flex-row items-center">
          <strong className="pl-10 text-lg">Transacciones</strong>
          {/* Agregar los nuevos inputs */}
          <div className="ml-3 flex gap-4">
            <DateInput
              value={fecha}
              onChange={setFecha}
              label="Fecha"
              required
            />
            <TimeInput value={hora} onChange={setHora} label="Hora" />
          </div>
          <Button
            className="ml-3"
            label="Actualizar lista"
            onClick={() => getTransacciones()}
          />
       <Button
            className="ml-3"
            label="Exportar a XLS"
            onClick={() => exportTransacciones()}
            theme="green"
          />
        </div>
        <div className="flex flex-col px-10 py-2">
          {eventos.length < 1 && <h2>No se encontraron transacciones</h2>}
          <div className="tableListCont">
            <TableList list={eventos} />
          </div>
          <Pagination
            onPageChange={getTransacciones}
            currentPage={currentPage}
            totalCount={totalEvents}
          />
        </div>
      </div>
    </div>
  );
}

export default Transacciones;
